import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  server: {
    // This is optional because it's only used in development.
    // See https://next-auth.js.org/deployment.
    NEXT_PUBLIC_URL: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url().min(1),
    STRIPE_SECRET_KEY: z.string().min(1),
    STRIPE_WEBHOOK_SECRET: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().min(1),
    NEXT_PRIVATE_SUPABASE_DOMAIN: z.string().min(1),
    NEXT_PRIVATE_SUPABASE_SERVICE_KEY: z.string().min(1),
    NEXT_PRIVATE_TEMPORAL_ADDRESS: z.string().min(1),
    NEXT_PRIVATE_TEMPORAL_NAMESPACE: z.string().min(1),
    NEXT_PRIVATE_TEMPORAL_API_ID: z.string().min(1),
    NEXT_PRIVATE_TEMPORAL_API_KEY: z.string().min(1),
    NEXT_PRIVATE_TASK_QUEUE: z.string().min(1),
    NEXT_PUBLIC_ZILLOW_ACCESS_TOKEN: z.string().min(1),
    NEXT_PUBLIC_HELLOSIGN_CLIENT_ID: z.string().min(1),
    NEXT_PRIVATE_HELLOSIGN_API_KEY: z.string().min(1),
    NEXT_PUBLIC_SCHOOL_DIGGER_ID: z.string().min(1),
    NEXT_PUBLIC_SCHOOL_DIGGER_KEY: z.string().min(1),
    NEXT_PUBLIC_SCHOOL_DIGGER_URL: z.string().min(1),
    NEXT_PUBLIC_WALK_API_URL: z.string().min(1),
    NEXT_PUBLIC_WALK_API_KEY: z.string().min(1),
    NEXT_PUBLIC_MATTER_PORT_SDK_KEY: z.string().min(1),
    NEXT_PRIVATE_POSTMARK_API_KEY: z.string().min(1),
    NEXT_PRIVATE_BRIDGE_PARCELS_URL: z.string().min(1),
    NEXT_PRIVATE_OPENAI_API_KEY: z.string().min(1),
    KV_REST_API_URL: z.string().min(1),
    KV_REST_API_TOKEN: z.string().min(1),
  },
  client: {
    NEXT_PUBLIC_APP_URL: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_CLIENT_SECRET: z.string().min(1),
    NEXT_PUBLIC_SUB_DOMAIN: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string().min(1),
    NEXT_PUBLIC_MAP_ID: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_ZILLOW_ACCESS_TOKEN: z.string().min(1),
    NEXT_PUBLIC_HELLOSIGN_CLIENT_ID: z.string().min(1),
    NEXT_PUBLIC_SCHOOL_DIGGER_ID: z.string().min(1),
    NEXT_PUBLIC_SCHOOL_DIGGER_KEY: z.string().min(1),
    NEXT_PUBLIC_SCHOOL_DIGGER_URL: z.string().min(1),
    NEXT_PUBLIC_WALK_API_URL: z.string().min(1),
    NEXT_PUBLIC_WALK_API_KEY: z.string().min(1),
    NEXT_PUBLIC_MATTER_PORT_SDK_KEY: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_UPLOAD_URL: z.string().min(1),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url().min(1),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().min(1),
    NEXT_PUBLIC_POSTHOG_HOST: z.string().min(1),
  },
  runtimeEnv: {
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    NEXT_PUBLIC_GOOGLE_CLIENT_SECRET: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_SUB_DOMAIN: process.env.NEXT_PUBLIC_SUB_DOMAIN,
    NEXT_PRIVATE_SUPABASE_SERVICE_KEY: process.env.NEXT_PRIVATE_SUPABASE_SERVICE_KEY,
    NEXT_PRIVATE_TEMPORAL_ADDRESS: process.env.NEXT_PRIVATE_TEMPORAL_ADDRESS,
    NEXT_PRIVATE_TEMPORAL_NAMESPACE: process.env.NEXT_PRIVATE_TEMPORAL_NAMESPACE,
    NEXT_PRIVATE_TEMPORAL_API_ID: process.env.NEXT_PRIVATE_TEMPORAL_API_ID,
    NEXT_PRIVATE_TEMPORAL_API_KEY: process.env.NEXT_PRIVATE_TEMPORAL_API_KEY,
    NEXT_PRIVATE_TASK_QUEUE: process.env.NEXT_PRIVATE_TASK_QUEUE,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    NEXT_PUBLIC_MAP_ID: process.env.NEXT_PUBLIC_MAP_ID,
    NEXT_PRIVATE_SUPABASE_DOMAIN: process.env.NEXT_PRIVATE_SUPABASE_DOMAIN,
    NEXT_PUBLIC_ZILLOW_ACCESS_TOKEN:
      process.env.NEXT_PUBLIC_ZILLOW_ACCESS_TOKEN,
    NEXT_PUBLIC_HELLOSIGN_CLIENT_ID:  process.env.NEXT_PUBLIC_HELLOSIGN_CLIENT_ID,
    NEXT_PRIVATE_HELLOSIGN_API_KEY: process.env.NEXT_PRIVATE_HELLOSIGN_API_KEY,
    NEXT_PUBLIC_SCHOOL_DIGGER_ID: process.env.NEXT_PUBLIC_SCHOOL_DIGGER_ID,
    NEXT_PUBLIC_SCHOOL_DIGGER_KEY: process.env.NEXT_PUBLIC_SCHOOL_DIGGER_KEY,
    NEXT_PUBLIC_SCHOOL_DIGGER_URL: process.env.NEXT_PUBLIC_SCHOOL_DIGGER_URL,
    NEXT_PUBLIC_WALK_API_URL: process.env.NEXT_PUBLIC_WALK_API_URL,
    NEXT_PUBLIC_WALK_API_KEY: process.env.NEXT_PUBLIC_WALK_API_KEY,
    NEXT_PUBLIC_MATTER_PORT_SDK_KEY: process.env.NEXT_PUBLIC_MATTER_PORT_SDK_KEY,
    NEXT_PRIVATE_POSTMARK_API_KEY: process.env.NEXT_PRIVATE_POSTMARK_API_KEY,
    NEXT_PUBLIC_SUPABASE_UPLOAD_URL: process.env.NEXT_PUBLIC_SUPABASE_UPLOAD_URL,
    NEXT_PRIVATE_BRIDGE_PARCELS_URL: process.env.NEXT_PRIVATE_BRIDGE_PARCELS_URL,
    NEXT_PRIVATE_OPENAI_API_KEY: process.env.NEXT_PRIVATE_OPENAI_API_KEY,
    KV_REST_API_URL: process.env.KV_REST_API_URL,
    KV_REST_API_TOKEN: process.env.KV_REST_API_TOKEN,
  },
})
